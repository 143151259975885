import React, { useState } from 'react';
import { AppstoreOutlined, MailOutlined, SettingOutlined } from '@ant-design/icons';
import { Col, Image, Menu, Row } from 'antd';
import nubit_logo from "../assets/images/nubit_logo.png"

const items = [
  {
    label: 'Navigation One',
    key: 'mail',
    icon: <MailOutlined />,
  },
  {
    label: 'Navigation Two',
    key: 'app',
    icon: <AppstoreOutlined />,
    disabled: true,
  },
  {
    label: 'Navigation Three - Submenu',
    key: 'SubMenu',
    icon: <SettingOutlined />,
    children: [
      {
        type: 'group',
        label: 'Item 1',
        children: [
          {
            label: 'Option 1',
            key: 'setting:1',
          },
          {
            label: 'Option 2',
            key: 'setting:2',
          },
        ],
      },
      {
        type: 'group',
        label: 'Item 2',
        children: [
          {
            label: 'Option 3',
            key: 'setting:3',
          },
          {
            label: 'Option 4',
            key: 'setting:4',
          },
        ],
      },
    ],
  },
  {
    label: (
      <a href="https://ant.design" target="_blank" rel="noopener noreferrer">
        Navigation Four - Link
      </a>
    ),
    key: 'alipay',
  },
];
const Header = () => {
  // const [current, setCurrent] = useState('mail');
  // const onClick = (e) => {
  //   console.log('click ', e);
  //   setCurrent(e.key);
  // };
  // return <Menu onClick={onClick} selectedKeys={[current]} mode="horizontal" items={items} />;
  return(
    <Row style={{borderBottom:"1px solid"}}>
      <Col span={6} style={{padding:"5px"}}><Image width={150} src={nubit_logo}/></Col>
      <Col span={18}><h1>LineUp Presentation</h1></Col>
    </Row>
  );
};
export default Header;