import React from "react";
import { Button, Col, Row, Tabs } from "antd";
const onChange = (key) => {
  console.log(key);
};
const items = [
  {
    key: "1",
    label: "Hospital",
    children: (
      <>
        <Row>
          <Col span={24}>
            <Button type="primary" href="https://lineup.pk/Home/Tvscreen" target="_blank">Screen 1</Button>
          </Col>
          <Col span={24} style={{marginTop:"2%"}}>
            <Button type="primary" href="https://lineup.pk/Home/Screen2" target="_blank">Screen 2</Button>
          </Col>
          <Col span={24} style={{marginTop:"2%"}}>
            <Button type="primary" href="https://lineup.pk/Home/Screen3" target="_blank">Screen 3</Button>
          </Col>
          <Col span={24} style={{marginTop:"2%"}}>
            <Button type="primary" href="https://lineup.pk/Home/Screen4" target="_blank">Screen 4</Button>
          </Col>
        </Row>
      </>
    ),
  },
  {
    key: "2",
    label: "School",
    children: (
      <>
        <Row>
          <Col span={24}>
            <Button type="primary" href="http://nizamuddintai-001-site53.ctempurl.com/" target="_blank">Screen 1</Button>
          </Col>
          <Col span={24} style={{marginTop:"2%"}}>
            <Button type="primary" href="http://nizamuddintai-001-site57.ctempurl.com/" target="_blank">Screen 2</Button>
          </Col>
        </Row>
      </>
    ),
  },
  {
    key: "3",
    label: "Forex Exchange",
    children: (
      <>
        <Row>
          <Col span={24}>
            <Button type="primary" href="http://nizamuddintai-001-site42.ctempurl.com/?brcode=000062" target="_blank">Screen 1</Button>
          </Col>
        </Row>
      </>
    ),
  },
  {
    key: "4",
    label: "Store",
    children: (
      <>
        <Row>
          <Col span={24}>
            <Button type="primary" href="http://nizamuddintai-001-site54.ctempurl.com/" target="_blank">Screen 1</Button>
          </Col>
        </Row>
      </>
    ),
  },
  {
    key: "5",
    label: "Entertainment",
    children: (
      <>
        <Row>
          <Col span={24}>
            <Button type="primary" href="http://nizamuddintai-001-site55.ctempurl.com/" target="_blank">Screen 1</Button>
          </Col>
        </Row>
      </>
    ),
  },
];
const TabsComponent = () => {
  return (
    <Row>
      <Col xs={{ span: 7, offset: 1 }} lg={{ span: 8, offset: 2 }}>
        <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
      </Col>
    </Row>
  );
};
export default TabsComponent;
