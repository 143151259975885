import logo from "./logo.svg";
import "./App.css";
import Slider from "./components/Slider";
import TabsComponent from "./components/Tabs";
import Header from "./components/Header";

function App() {
  return (
    // <Slider/>
    <>
      <Header />
      <TabsComponent />
    </>
  );
}

export default App;
